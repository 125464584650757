<template>
  <section>
    <b-form-row v-if="restrictMultipleCitySelection">
      <b-col>
        <b-alert show variant="danger">{{ DISPLAY_MESSAGES.MULTIPLE_CITIES_ADDRESS_SETUP_ERROR }}</b-alert>
      </b-col>
    </b-form-row>
    <AddressSetup v-else />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { DISPLAY_MESSAGES } from '../../../utilities/constants'
import AddressSetup from '../../cities/AddressSetup.vue'

export default {
  name: 'Address',
  data() {
    return {
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
    }
  },
  components: {
    AddressSetup,
  },
  computed: {
    restrictMultipleCitySelection() {
      return this.selectedTenant.length > 1 || this.selectedTenant.some((tenant) => tenant.tenantID === 0)
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
    }),
  },
}
</script>
<style lang="scss" scoped></style>
